<template>
    <div class="toBePaid">
        <div class="toBePaid-head">
         <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
      </div>
      <div class="toBePaid-middle">
          <div class="toBePaid-content">
              <div class="toBePaid-container">
                  <div class="toBePaid-top">
                    <div class="order-time">
                        <span>下单时间：</span>
                        <span>2021-08-10 10:25:42</span>
                    </div>
                    <div class="order-jifen">
                        <span>10000</span>
                        <span>积分</span>
                    </div>
                </div>
                <div class="toBePaid-shopInfo">
                    <div class="shop-img">
                        <img src="../assets/images/shop/u95.png"/>
                    </div>
                    <div class="shop-basicInfo">
                        <div class="shop-tite">华为智能耳机</div>
                        <div class="shop-money">
                            <i class="iconfont icon-jinbi"></i>
                            <span class="money">10000</span>
                        </div>
                        <div class="shop-total">
                            <span>数量</span>
                            <span>1</span>
                        </div>
                    </div>
                    <div class="shop-btn">
                        <a @click="goPay()">去支付</a>
                    </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'toBePaid',
    methods: {
        goHistotry() {
            this.$router.go(-1);
        },
        goPay() {
            this.$router.push('/pay');
        }
    },
}
</script>

<style scoped>
@import '../assets/css/toBeCommon.less';
</style>